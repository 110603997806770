import Vue from "vue"
import Products from "./components/Products.vue"

Vue.config.productionTip = false

const mountEls = document.querySelectorAll("#mp-app-products")

mountEls.forEach((mountEl) => {
  const layoutType = mountEl.getAttribute("data-layout-type") || "row"
  const qty = mountEl.getAttribute("data-qty") || 4
  const featured = mountEl.getAttribute("data-featured") || false
  const category = mountEl.getAttribute("data-category") || ""
  const showBtn = mountEl.getAttribute("data-show-btn") || false

  new Vue({
    render: (h) => h(Products),
    el: mountEl,
    data: {
      layoutType,
      qty,
      featured,
      category,
      showBtn,
    },
  })
})
