// import data from '../products.json'
const WooCommerceRestApi = require("@woocommerce/woocommerce-rest-api").default

export default {
  data() {
    return {
      products: [],
      consumerKey: "ck_09e3f28472cdfc23179b6e129943b8fe2bc2a6ae",
      consumerSecret: "cs_c320e2a08fe38846bb240706abda187c070757a4",
      version: "wc/v3",
      api: {},
    }
  },
  computed: {
    mpOrigin() {
      const host = window.location.host
      const hostSubdomain = host.split(".")[0]
      let env = hostSubdomain
      switch (hostSubdomain) {
        case "localhost:8080":
          env = ""
          break
        case "www":
          env = ""
          break
      }

      return `https://${env}marketplace.homeschool.com`
    },
    placeHolderImage() {
      return `${this.mpOrigin}/wp-content/uploads/woocommerce-placeholder-600x600.png`
    },
  },
  methods: {
    async setProducts(per_page = 4, category = "", featured = false) {
      this.productsLoading = true

      const { data: products } = await this.api.get("products", {
        per_page,
        featured,
        category,
      })

      this.productsLoading = false
      this.products = products
    },
  },
  created() {
    this.api = new WooCommerceRestApi({
      url: this.mpOrigin,
      consumerKey: this.consumerKey,
      consumerSecret: this.consumerSecret,
      version: this.version,
    })
  },
}
