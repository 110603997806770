<template>
  <section>
    <div v-if="productsLoading" class="row">
      <div v-for="(item, index) in Array(0, 0, 0, 0)" :key="index" class="col-md-3 col-sm-2">
        <a>
          <img :src="placeHolderImage" atl="blank" class="img-responsive" />
        </a>
      </div>
    </div>

    <div v-if="showProductsRow" class="row">
      <div v-for="(item, index) in productsViewData" :key="index" class="col-md-3 col-sm-2">
        <a :href="item.permalink">
          <img :src="item._imagesrc" :atl="item._imagealt" class="img-responsive" />
        </a>
        <div v-html="item.short_description"></div>
        <a v-if="showBtn" class="btn btn-primary primary-action h4" :href="item.permalink" role="button"
          data-slide="next">View</a>
      </div>
    </div>

    <div v-if="showProductsGrid">
      <div class="row" v-for="(itemRow, index) in productsFavoritesRows" :key="index">
        <div class="col-sm-6" v-for="(itemCol, index2) in itemRow" :key="index2">
          <div class="row">
            <div class="col-md-10">
              <h4><a :href="itemCol.permalink">{{ itemCol.name }}</a></h4>
              <span class="small" v-html="itemCol.short_description"></span>
            </div>
            <div class="col-md-2">
              <a v-if="showBtn" class="btn btn-primary primary-action h4 pull-right" :href="itemCol.permalink"
                role="button" data-slide="next">View</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="display: none">v 1.0</div>
  </section>
</template>

<script>
/*
 * COPYIED FROM ProductsFeatured
 */
import WooCommerceRestApiMixin from '../mixins/WooCommerceRestApiMixin'

export default {
  name: 'Products',
  mixins: [WooCommerceRestApiMixin],
  data() {
    return {
      layoutType: this.$parent.layoutType,
      qty: this.$parent.qty,
      featured: this.$parent.featured,
      category: this.$parent.category,
      showBtn: this.$parent.showBtn
    }
  },
  computed: {
    productsViewData() {
      return this.products.map((item) => {
        let _imagesrc = item.images[0]?.src
        let _imagealt = item.images[0]?.alt

        if (!_imagesrc) {
          _imagesrc = this.placeHolderImage
        }

        // if src does not start with http append domain (set to prod for now)
        if (!(/^https:/i).test(_imagesrc)) {
          _imagesrc = `${this.mpOrigin}${_imagesrc}`
        }

        if (!_imagealt) {
          _imagealt = 'Product Image'
        }

        return { ...item, _imagesrc, _imagealt }
      })
    },
    productsFavoritesRows() {

      // li'l util func
      function splitArrayIntoColumns(arr) {
        const columns = [];
        for (let i = 0; i < arr.length; i += 2) {
          const column = arr.slice(i, i + 2);
          columns.push(column);
        }
        return columns;
      }

      return splitArrayIntoColumns(this.products)
    },
    showProductsRow() {
      return this.layoutType === 'row'
    },
    showProductsGrid() {
      return this.layoutType === 'grid'
    }
  },
  created() {
    this.setProducts(this.qty, this.category, this.featured)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
